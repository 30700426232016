<template>
	<div class="energy-home">
		<div class="all">
			<tab-left />
			<div class="right">
				<header-top />
				<div class="page-title">
					<span>实时能耗</span>
					<div class="title-right">
						<ul>
							<li>主页</li>
							<li>/</li>
							<li>系统预览</li>
							<li>/</li>
							<li>首页</li>
						</ul>
					</div>
				</div>
				<div class="echart">
					<div>
						<div class="main" id="main1"></div>
						<div class="count count1">
							<animate-number
								from="0"
								to="9562"
								duration="2000"
							></animate-number>
						</div>
					</div>
					<div>
						<div class="main" id="main2"></div>
						<div class="count count2">
							<animate-number
								from="0"
								to="10000"
								duration="2000"
							></animate-number>
						</div>
					</div>
					<div>
						<div class="main" id="main3"></div>
						<div class="count count3">
							<animate-number
								from="0"
								to="5000"
								duration="2000"
							></animate-number>
						</div>
					</div>
					<div>
						<div class="main" id="main4"></div>
						<div class="count count4">
							<animate-number
								from="0"
								to="8555"
								duration="2000"
							></animate-number>
						</div>
					</div>
					<div>
						<div class="main" id="main5"></div>
						<div class="count count5">
							<animate-number
								from="0"
								to="6444"
								duration="2000"
							></animate-number>
						</div>
					</div>
				</div>
				<div class="content">
					<div class="big-echart">
						<div class="top">
							<span>用电总量统计(KW·H)</span>
							<span><i class="el-icon-more" style="cursor:pointer;"></i></span>
						</div>
						<div id="main6"></div>
						<div class="notice">
							<div class="listview">
								<div class="listview-top">
									<span>重要通知</span>
									<span
										><i class="el-icon-more" style="cursor:pointer;"></i
									></span>
								</div>
								<div class="media" v-for="i in 5" :key="i">
									<div class="pull-left">
										<img
											width="40"
											src="@/assets/images/energyManage/1.png"
											alt=""
										/>
									</div>
									<div class="media-body">
										<small class="text-muted">调度班长 - 2分钟前</small><br />
										<a class="t-overflow" href="#"
											>**公司数据采集异常，请工作人员检查。</a
										>
									</div>
								</div>
								<div class="listview-footer">
									<span>查看所有</span>
								</div>
							</div>
							<div class="commission">
								<div class="commission-top">
									<span>待办事项</span>
									<span
										><i class="el-icon-more" style="cursor:pointer;"></i
									></span>
								</div>
								<div class="media">
									<img src="@/assets/images/energyManage/sort.png" alt="" />
									<div
										class="checkBox"
										@click="showCheckBox1 = !showCheckBox1"
										:class="{ selected: showCheckBox1 }"
									></div>
									<span class="text" :class="{ decoration: showCheckBox1 }">
										检查办公楼新风系统机组运行状况
									</span>
								</div>
								<div class="media">
									<img src="@/assets/images/energyManage/sort.png" alt="" />
									<div
										class="checkBox"
										@click="showCheckBox2 = !showCheckBox2"
										:class="{ selected: showCheckBox2 }"
									></div>
									<span class="text" :class="{ decoration: showCheckBox2 }">
										空调系统能耗监测报告提交
									</span>
								</div>
								<div class="media">
									<img src="@/assets/images/energyManage/sort.png" alt="" />
									<div
										class="checkBox"
										@click="showCheckBox3 = !showCheckBox3"
										:class="{ selected: showCheckBox3 }"
									></div>
									<span class="text" :class="{ decoration: showCheckBox3 }">
										处理办公楼照明系统告警
									</span>
								</div>
								<div class="media">
									<img src="@/assets/images/energyManage/sort.png" alt="" />
									<div
										class="checkBox"
										@click="showCheckBox4 = !showCheckBox4"
										:class="{ selected: showCheckBox4 }"
									></div>
									<span class="text" :class="{ decoration: showCheckBox4 }">
										输出全面的能源审计报告
									</span>
								</div>
								<div class="commission-top">
									<span>待办事项</span>
									<span
										><i class="el-icon-more" style="cursor:pointer;"></i
									></span>
								</div>
								<div class="media">
									<img src="@/assets/images/energyManage/sort.png" alt="" />
									<div class="checkBox selected"></div>
									<span class="text decoration">
										建筑总能耗报表生成
									</span>
								</div>
								<div class="media">
									<img src="@/assets/images/energyManage/sort.png" alt="" />
									<div class="checkBox selected"></div>
									<span class="text decoration">
										根据主管部门的能源数据监测要求，上报能耗数据。
									</span>
								</div>
								<div class="media">
									<img src="@/assets/images/energyManage/sort.png" alt="" />
									<div class="checkBox selected"></div>
									<span class="text decoration">
										能耗单元统计
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="map">
						<div class="top">
							<span>地图</span>
							<span><i class="el-icon-more" style="cursor:pointer;"></i></span>
						</div>
						<!-- <div class="map-container" id="chainMap"></div> -->
						<div class="map-container" id="yzMap"></div>
						<div class="top">
							<span>实时监测</span>
							<span><i class="el-icon-more" style="cursor:pointer;"></i></span>
						</div>
						<div class="flot" id="line"></div>
						<div class="top">
							<span>实时排名</span>
							<span><i class="el-icon-more" style="cursor:pointer;"></i></span>
						</div>
						<div class="media">
							<span>空调系统</span>
							<span class="num num1">785879</span>
						</div>
						<div class="media">
							<span>供暖系统</span>
							<span class="num num2">78879</span>
						</div>
						<div class="media">
							<span>照明系统</span>
							<span class="num num3">7796</span>
						</div>
						<div class="media">
							<span>办公设备</span>
							<span class="num num4">779</span>
						</div>
						<div class="media">
							<span>其他</span>
							<span class="num num5">79</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="nav">
			<ul>
				<li>
					<i class="el-icon-menu"></i>
				</li>
				<el-tooltip
					class="item"
					effect="dark"
					:content="item.type"
					placement="right"
					v-for="(item, index) in list"
					:key="index"
				>
					<li @click="jump(index)">
						<i :class="item.class"></i>
					</li>
				</el-tooltip>
			</ul>
		</div>
	</div>
</template>

<script>
import * as echarts from 'echarts'
// import dataJson from '../../assets/json/data.json'
import tabLeft from './components/tab-left.vue'
import headerTop from './components/header.vue'
export default {
	components: { tabLeft, headerTop },
	data() {
		return {
			showCheckBox1: false,
			showCheckBox2: false,
			showCheckBox3: false,
			showCheckBox4: false,
			list: [
				{ type: '运行监测', class: 'el-icon-wallet' },
				{ type: 'KPI', class: 'el-icon-box' },
				{ type: '全息图概括', class: 'el-icon-s-data' },
				{ type: '能耗统计', class: 'el-icon-finished' },
				{ type: '电费管理', class: 'el-icon-money' },
				{ type: '设备管理', class: 'el-icon-suitcase' },
				{ type: '报表管理', class: 'el-icon-date' },
				{ type: '用户管理', class: 'el-icon-user' },
				{ type: '泵房导航', class: 'el-icon-location' },
				{ type: '系统设置', class: 'el-icon-setting' },
				{ type: '资产管理', class: 'el-icon-coin' },
				{ type: '门禁管理', class: 'el-icon-lock' },
				{ type: '资产分析', class: 'el-icon-data-line' },
			],
		}
	},

	methods: {
		drawLine1() {
			// 基于准备好的dom，初始化echarts实例
			let myChart = echarts.init(document.getElementById('main1'))
			// 指定图表的配置项和数据
			let option = {
				tooltip: {
					trigger: 'axis',
				},
				legend: {
					textStyle: {
						color: '#4c9bfd', // 图例文字颜色
					},
					right: '10%', // 距离右边10%
				},
				grid: {
					top: '20%',
					left: '3%',
					right: '4%',
					bottom: '3%',
					show: true, // 显示边框
					borderColor: '#012f4a', // 边框颜色
					containLabel: true, // 包含刻度文字在内
				},
				xAxis: {
					type: 'category',
					data: [
						'1月',
						'2月',
						'3月',
						'4月',
						'5月',
						'6月',
						'7月',
						'8月',
						'9月',
						'10月',
						'11月',
						'12月',
					],
					axisTick: {
						show: false,
					},
					// 文本颜色
					axisLabel: {
						color: '#4c9bfd',
					},
					// 让线条起点在轴上
					boundaryGap: false,
				},
				yAxis: {
					type: 'value',
					// 去除多余坐标
					axisTick: {
						show: false,
					},
					// 文字颜色
					axisLabel: {
						color: '#4c9bfd',
					},
					// 分割线颜色
					splitLine: {
						lineStyle: {
							color: '#012f4a',
						},
					},
				},
				series: [
					{
						name: '预期销售额',
						data: [24, 40, 101, 134, 90, 230, 210, 230, 120, 230, 210, 120],
						type: 'line',
						//线条圆滑
						smooth: true,
					},
					{
						name: '实际销售额',
						data: [40, 64, 191, 324, 290, 330, 310, 213, 180, 200, 180, 79],
						type: 'line',
						//线条圆滑
						smooth: true,
					},
				],
				color: ['#00f2f1', '#ed3f35'],
			}
			myChart.setOption(option)
		},
		drawLine2() {
			// 基于准备好的dom，初始化echarts实例
			let myChart = echarts.init(document.getElementById('main2'))
			// 指定图表的配置项和数据
			let option = {
				tooltip: {
					trigger: 'axis',
				},
				legend: {
					textStyle: {
						color: '#4c9bfd', // 图例文字颜色
					},
					right: '10%', // 距离右边10%
				},
				grid: {
					top: '20%',
					left: '3%',
					right: '4%',
					bottom: '3%',
					show: true, // 显示边框
					borderColor: '#012f4a', // 边框颜色
					containLabel: true, // 包含刻度文字在内
				},
				xAxis: {
					type: 'category',
					data: [
						'1月',
						'2月',
						'3月',
						'4月',
						'5月',
						'6月',
						'7月',
						'8月',
						'9月',
						'10月',
						'11月',
						'12月',
					],
					axisTick: {
						show: false,
					},
					// 文本颜色
					axisLabel: {
						color: '#4c9bfd',
					},
					// 让线条起点在轴上
					boundaryGap: false,
				},
				yAxis: {
					type: 'value',
					// 去除多余坐标
					axisTick: {
						show: false,
					},
					// 文字颜色
					axisLabel: {
						color: '#4c9bfd',
					},
					// 分割线颜色
					splitLine: {
						lineStyle: {
							color: '#012f4a',
						},
					},
				},
				series: [
					{
						name: '预期销售额',
						data: [24, 40, 101, 134, 90, 230, 210, 230, 120, 230, 210, 120],
						type: 'line',
						//线条圆滑
						smooth: true,
					},
					{
						name: '实际销售额',
						data: [40, 64, 191, 324, 290, 330, 310, 213, 180, 200, 180, 79],
						type: 'line',
						//线条圆滑
						smooth: true,
					},
				],
				color: ['#00f2f1', '#ed3f35'],
			}
			myChart.setOption(option)
		},
		drawLine3() {
			// 基于准备好的dom，初始化echarts实例
			let myChart = echarts.init(document.getElementById('main3'))
			// 指定图表的配置项和数据
			let option = {
				tooltip: {
					trigger: 'axis',
				},
				legend: {
					textStyle: {
						color: '#4c9bfd', // 图例文字颜色
					},
					right: '10%', // 距离右边10%
				},
				grid: {
					top: '20%',
					left: '3%',
					right: '4%',
					bottom: '3%',
					show: true, // 显示边框
					borderColor: '#012f4a', // 边框颜色
					containLabel: true, // 包含刻度文字在内
				},
				xAxis: {
					type: 'category',
					data: [
						'1月',
						'2月',
						'3月',
						'4月',
						'5月',
						'6月',
						'7月',
						'8月',
						'9月',
						'10月',
						'11月',
						'12月',
					],
					axisTick: {
						show: false,
					},
					// 文本颜色
					axisLabel: {
						color: '#4c9bfd',
					},
					// 让线条起点在轴上
					boundaryGap: false,
				},
				yAxis: {
					type: 'value',
					// 去除多余坐标
					axisTick: {
						show: false,
					},
					// 文字颜色
					axisLabel: {
						color: '#4c9bfd',
					},
					// 分割线颜色
					splitLine: {
						lineStyle: {
							color: '#012f4a',
						},
					},
				},
				series: [
					{
						name: '预期销售额',
						data: [24, 40, 101, 134, 90, 230, 210, 230, 120, 230, 210, 120],
						type: 'line',
						//线条圆滑
						smooth: true,
					},
					{
						name: '实际销售额',
						data: [40, 64, 191, 324, 290, 330, 310, 213, 180, 200, 180, 79],
						type: 'line',
						//线条圆滑
						smooth: true,
					},
				],
				color: ['#00f2f1', '#ed3f35'],
			}
			myChart.setOption(option)
		},
		drawLine4() {
			// 基于准备好的dom，初始化echarts实例
			let myChart = echarts.init(document.getElementById('main4'))
			// 指定图表的配置项和数据
			let option = {
				tooltip: {
					trigger: 'axis',
				},
				legend: {
					textStyle: {
						color: '#4c9bfd', // 图例文字颜色
					},
					right: '10%', // 距离右边10%
				},
				grid: {
					top: '20%',
					left: '3%',
					right: '4%',
					bottom: '3%',
					show: true, // 显示边框
					borderColor: '#012f4a', // 边框颜色
					containLabel: true, // 包含刻度文字在内
				},
				xAxis: {
					type: 'category',
					data: [
						'1月',
						'2月',
						'3月',
						'4月',
						'5月',
						'6月',
						'7月',
						'8月',
						'9月',
						'10月',
						'11月',
						'12月',
					],
					axisTick: {
						show: false,
					},
					// 文本颜色
					axisLabel: {
						color: '#4c9bfd',
					},
					// 让线条起点在轴上
					boundaryGap: false,
				},
				yAxis: {
					type: 'value',
					// 去除多余坐标
					axisTick: {
						show: false,
					},
					// 文字颜色
					axisLabel: {
						color: '#4c9bfd',
					},
					// 分割线颜色
					splitLine: {
						lineStyle: {
							color: '#012f4a',
						},
					},
				},
				series: [
					{
						name: '预期销售额',
						data: [24, 40, 101, 134, 90, 230, 210, 230, 120, 230, 210, 120],
						type: 'line',
						//线条圆滑
						smooth: true,
					},
					{
						name: '实际销售额',
						data: [40, 64, 191, 324, 290, 330, 310, 213, 180, 200, 180, 79],
						type: 'line',
						//线条圆滑
						smooth: true,
					},
				],
				color: ['#00f2f1', '#ed3f35'],
			}
			myChart.setOption(option)
		},
		drawLine5() {
			// 基于准备好的dom，初始化echarts实例
			let myChart = echarts.init(document.getElementById('main5'))
			// 指定图表的配置项和数据
			let option = {
				tooltip: {
					trigger: 'axis',
				},
				legend: {
					textStyle: {
						color: '#4c9bfd', // 图例文字颜色
					},
					right: '10%', // 距离右边10%
				},
				grid: {
					top: '20%',
					left: '3%',
					right: '4%',
					bottom: '3%',
					show: true, // 显示边框
					borderColor: '#012f4a', // 边框颜色
					containLabel: true, // 包含刻度文字在内
				},
				xAxis: {
					type: 'category',
					data: [
						'1月',
						'2月',
						'3月',
						'4月',
						'5月',
						'6月',
						'7月',
						'8月',
						'9月',
						'10月',
						'11月',
						'12月',
					],
					axisTick: {
						show: false,
					},
					// 文本颜色
					axisLabel: {
						color: '#4c9bfd',
					},
					// 让线条起点在轴上
					boundaryGap: false,
				},
				yAxis: {
					type: 'value',
					// 去除多余坐标
					axisTick: {
						show: false,
					},
					// 文字颜色
					axisLabel: {
						color: '#4c9bfd',
					},
					// 分割线颜色
					splitLine: {
						lineStyle: {
							color: '#012f4a',
						},
					},
				},
				series: [
					{
						name: '预期销售额',
						data: [24, 40, 101, 134, 90, 230, 210, 230, 120, 230, 210, 120],
						type: 'line',
						//线条圆滑
						smooth: true,
					},
					{
						name: '实际销售额',
						data: [40, 64, 191, 324, 290, 330, 310, 213, 180, 200, 180, 79],
						type: 'line',
						//线条圆滑
						smooth: true,
					},
				],
				color: ['#00f2f1', '#ed3f35'],
			}
			myChart.setOption(option)
		},
		drawLine6() {
			// 基于准备好的dom，初始化echarts实例
			let myChart = echarts.init(document.getElementById('main6'))
			// 指定图表的配置项和数据
			let option = {
				tooltip: {
					trigger: 'axis',
				},
				legend: {
					textStyle: {
						color: '#4c9bfd', // 图例文字颜色
					},
					right: '10%', // 距离右边10%
				},
				grid: {
					top: '20%',
					left: '3%',
					right: '4%',
					bottom: '3%',
					show: true, // 显示边框
					borderColor: '#012f4a', // 边框颜色
					containLabel: true, // 包含刻度文字在内
				},
				xAxis: {
					type: 'category',
					data: [
						'1月',
						'2月',
						'3月',
						'4月',
						'5月',
						'6月',
						'7月',
						'8月',
						'9月',
						'10月',
						'11月',
						'12月',
					],
					axisTick: {
						show: false,
					},
					// 文本颜色
					axisLabel: {
						color: '#4c9bfd',
					},
					// 让线条起点在轴上
					boundaryGap: false,
				},
				yAxis: {
					type: 'value',
					// 去除多余坐标
					axisTick: {
						show: false,
					},
					// 文字颜色
					axisLabel: {
						color: '#4c9bfd',
					},
					// 分割线颜色
					splitLine: {
						lineStyle: {
							color: '#012f4a',
						},
					},
				},
				series: [
					{
						name: '预期销售额',
						data: [24, 40, 101, 134, 90, 230, 210, 230, 120, 230, 210, 120],
						type: 'line',
						//线条圆滑
						smooth: true,
					},
					{
						name: '实际销售额',
						data: [40, 64, 191, 324, 290, 330, 310, 213, 180, 200, 180, 79],
						type: 'line',
						//线条圆滑
						smooth: true,
					},
				],
				color: ['#00f2f1', '#ed3f35'],
			}
			myChart.setOption(option)
		},
		randomData() {
			return Math.round(Math.random() * 500)
		},
		// drawMap() {
		// 	let myChart = echarts.init(document.getElementById('chainMap'))
		// 	let uploadDataUrl = dataJson
		// 	// 注册地图
		// 	echarts.registerMap('china', uploadDataUrl)
		// 	let option = {
		// 		geo: {
		// 			// 地理坐标系组件,支持在地理坐标系上绘制散点图、线图
		// 			map: 'china',
		// 			aspectScale: 0.75,
		// 			zoom: 1.1,
		// 		},
		// 		tooltip: {
		// 			formatter: '{b}:{c}',
		// 		},
		// 		// 省会的位置标注
		// 		// legend: {
		// 		//   orient: 'vertical',
		// 		//   left: 'left',
		// 		//   data:['']
		// 		// },
		// 		// visualMap: {
		// 		// 	min: 0,
		// 		// 	max: 1500,
		// 		// 	left: '10%',
		// 		// 	top: 'bottom',
		// 		// 	text: ['高', '低'],
		// 		// 	calculable: true,
		// 		// 	color: ['#0b50b9', '#FFFFFF'],
		// 		// },
		// 		series: [
		// 			{
		// 				zoom: 1.1,
		// 				map: 'china',
		// 				type: 'map',
		// 				itemStyle: {
		// 					normal: {
		// 						borderColor: 'rgba(0, 0, 0, 0.2)',
		// 					},
		// 					emphasis: {
		// 						shadowOffsetX: 0,
		// 						shadowOffsetY: 0,
		// 						shadowBlur: 20,
		// 						borderWidth: 0,
		// 						shadowColor: 'rgba(0, 0, 0, 0.5)',
		// 					},
		// 				},
		// 				label: {
		// 					normal: {
		// 						show: true,
		// 					},
		// 					emphasis: {
		// 						show: true,
		// 					},
		// 				},
		// 				data: this.dataList,
		// 			},
		// 		],
		// 	}
		// 	myChart.setOption(option)
		// 	window.onresize = function() {
		// 		myChart.resize()
		// 	}
		// },
		drawLine7() {
			let chartDom = document.getElementById('line')
			let myChart = echarts.init(chartDom)
			let option
			const time = (function() {
				// 立即执行函数
				let now = new Date() // 获得当前的时间
				let res = [] // 存放时间的数组
				let len = 500 // 要存几个时间？
				while (len--) {
					res.unshift(now.toLocaleTimeString().replace(/^\D*/, '')) // 转换时间，大家可以打印出来看一下
					now = new Date(+now - 200) // 延迟几秒存储一次？
				}
				return res
			})()
			const dataOne = (function() {
				// 5个随机数，大家可随意定义
				let res = []
				let len = 500
				while (len--) {
					res.push(Math.round(Math.random() * 1000))
				}
				return res
			})()
			const dataTwo = (function() {
				// 5个随机数
				let res = []
				let len = 500
				while (len--) {
					res.push(Math.round(Math.random() * 1000))
				}
				return res
			})()
			//配置项，可以去查一下官方文档
			option = {
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					top: '20%',
					left: '3%',
					right: '4%',
					bottom: '3%',
					show: true, // 显示边框
					borderColor: '#012f4a', // 边框颜色
					containLabel: true, // 包含刻度文字在内
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					axisLabel: {
						color: '#4c9bfd',
					},
				},

				yAxis: {
					type: 'value',
					axisLabel: {
						color: '#4c9bfd',
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						lineStyle: {
							color: '#012f4a',
						},
					},
				},
				series: [
					{
						name: 'Fake Data',
						type: 'line',
						symbol: 'none',
						sampling: 'lttb',
						itemStyle: {
							color: '#00f2f1',
						},
						areaStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
								{
									offset: 0,
									color: '#00f2f1',
								},
								{
									offset: 1,
									color: '#ed3f35',
								},
							]),
						},
					},
				],
			}
			setInterval(function() {
				let nowTime = new Date().toLocaleTimeString().replace(/^\D*/, '')
				time.shift()
				time.push(nowTime)
				dataOne.shift()
				dataOne.push(Math.round(Math.random() * 1000))
				dataTwo.shift()
				dataTwo.push(Math.round(Math.random() * 1000))
				myChart.setOption({
					xAxis: [
						{
							data: time,
						},
					],
					series: [
						{
							data: dataOne,
						},
						{
							data: dataTwo,
						},
					],
				})
			}, 500)

			option && myChart.setOption(option)
		},
		//导航栏
		jump(index) {
			if (index === 0) {
				this.$router.push('/energy-home')
			} else if (index === 1) {
				this.$router.push('/keyInfo')
			} else if (index === 2) {
				this.$router.push('/mstp-map')
			} else if (index === 3) {
				this.$router.push('/efficiencyAnalysis')
			} else if (index === 4) {
				this.$router.push('/dianfei')
			} else if (index === 5) {
				this.$router.push('/deviceManager')
			} else if (index === 6) {
				this.$router.push('/energy_consumption')
			} else if (index === 7) {
				this.$router.push('/userMng')
			} else if (index === 8) {
				this.$router.push('/pumpRoomNav')
			} else if (index === 9) {
				this.$router.push('/systemSettings')
			} else if (index === 10) {
				this.$router.push('/estate')
			} else if (index === 11) {
				this.$router.push('/door')
			} else {
				this.$router.push('/EstateAny')
			}
			this.activeShow = index
		},
	},
	mounted() {
		this.drawLine1()
		this.drawLine2()
		this.drawLine3()
		this.drawLine4()
		this.drawLine5()
		this.drawLine6()
		this.drawLine7()
		// this.drawMap()
		const a = new Promise((resolve, reject) => {
			console.log(reject)
			// 如果已加载直接返回
			if (window.T) {
				console.log('地图脚本初始化成功...')
				resolve(window.T)
			}
		})
		const map = new window.T.Map('yzMap')
		map.centerAndZoom(new window.T.LngLat(116.401003, 39.903117), 12)
		console.log(a)
	},
}
</script>

<style lang="scss" scoped>
.energy-home {
	*:focus,
	*:active {
		outline: none !important;
	}
	li {
		list-style: none;
	}
	background: url(~@/assets/images/energyManage/background.jpg) 700px 700px;
	display: flex;
	position: relative;
	.all {
		display: flex;
		margin-left: 50px;
		.right {
			flex: 8;
			padding: 0 20px;

			.listview {
				font-size: 14px;
				color: #fff;
				background: rgba(0, 0, 0, 0.35);
				.listview-top {
					padding: 5px 10px;
					display: flex;
					justify-content: space-between;
					border-bottom: 1px solid rgba(255, 255, 255, 0.15);
				}
				.media {
					display: flex;
					padding: 5px 10px;
					border-bottom: 1px solid rgba(255, 255, 255, 0.15);
					.pull-left {
						margin-right: 10px;
					}
					.media-body {
						.text-muted {
							color: #bbb;
						}
						.t-overflow {
							color: #fff;
						}
						.t-overflow:hover {
							color: #ffd600;
						}
					}
				}
				.media:hover,
				.listview-top:hover,
				.listview-footer:hover {
					background-color: rgba(0, 0, 0, 0.07);
				}
				.listview-footer {
					color: #fff;
					text-align: center;
				}
			}
			.page-title {
				font-size: 15px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.15);
				margin: 0;
				display: flex;
				color: #fff;
				justify-content: space-between;
				align-items: center;
				padding: 10px 0;
				.title-right {
					ul {
						display: flex;
						margin: 0;
						li {
							font-size: 13px;
							margin: 0 5px;
							cursor: pointer;
						}
						li:nth-child(1):hover,
						li:nth-child(3):hover,
						li:nth-child(5):hover {
							color: #ffd600;
						}
					}
				}
			}
			.echart {
				display: flex;
				justify-content: space-between;
				position: relative;
				.main {
					width: 320px;
					height: 110px;
					background: rgba(0, 0, 0, 0.15);
				}
				.count {
					position: absolute;
					color: #fff;
					font-size: 25px;
				}
				.count1 {
					top: -8px;
					left: 0;
				}
				.count2 {
					top: -8px;
					left: 320px;
				}
				.count3 {
					top: -8px;
					left: 640px;
				}
				.count4 {
					top: -8px;
					left: 960px;
				}
				.count5 {
					top: -8px;
					left: 1280px;
				}
			}
			.content {
				.top {
					background: rgba(0, 0, 0, 0.35);
					color: #fff;
					display: flex;
					width: 100%;
					justify-content: space-between;
					padding: 0 10px;
				}
				display: flex;
				.big-echart {
					flex: 2;
					margin-top: 20px;
					#main6 {
						width: 100%;
						height: 300px;
						margin-bottom: 20px;
						background: rgba(0, 0, 0, 0.15);
					}
					.notice {
						display: flex;
						.listview {
							margin-right: 20px;
							width: 50%;
							height: 320px;
						}
						.commission {
							font-size: 14px;
							width: 50%;
							color: #fff;
							background: rgba(0, 0, 0, 0.35);
							.commission-top {
								padding: 5px 10px;
								display: flex;
								justify-content: space-between;
								border-bottom: 1px solid rgba(255, 255, 255, 0.15);
							}
							.media {
								display: flex;
								padding: 5px 10px;
								height: 40px;
								border-bottom: 1px solid rgba(255, 255, 255, 0.15);
								.checkBox {
									margin-left: 20px;
									width: 14px;
									height: 14px;
									border: 1px solid rgba(255, 255, 255, 0.5);
									cursor: pointer;
									margin-top: 8px;
								}
								.selected {
									background: rgba(255, 255, 255, 0.8);
								}
								.decoration {
									text-decoration: line-through;
								}
								.text {
									margin-left: 10px;
									line-height: 30px;
								}
							}
							.media:hover {
								background: rgba(0, 0, 0, 0.07);
							}
						}
					}
				}
				.map {
					flex: 1;
					margin-left: 20px;
					margin-bottom: 20px;
					.map-container {
						height: 400px;
						background: rgba(0, 0, 0, 0.35);
					}
					.top {
						margin-top: 20px;
					}
					.flot {
						width: 100%;
						height: 300px;
						background: rgba(0, 0, 0, 0.15);
					}
					.media {
						display: flex;
						color: #fff;
						padding: 5px 10px;
						background: rgba(0, 0, 0, 0.35);
						font-size: 14px;
						justify-content: space-between;
						align-items: center;
						.num {
							padding: 0 6px;
							color: #fff;
							font-size: 23px;
							text-shadow: none;
						}
						.num1 {
							background: rgba(217, 83, 79, 0.56);
						}
						.num2 {
							background: rgba(255, 214, 0, 0.56);
						}
						.num3 {
							background: rgba(91, 192, 222, 0.56);
						}
						.num4 {
							background: rgba(92, 184, 92, 0.56);
						}
						.num5 {
							background: rgba(92, 235, 92, 0.96);
						}
					}
					.media:hover {
						background: rgba(255, 255, 255, 0.07);
					}
				}
			}
		}
	}
	.nav {
		position: absolute;
		left: 0;
		background: rgba(0, 0, 0, 0.75);
		top: 0;
		height: 100%;
		ul {
			display: flex;
			flex-direction: column;
			padding: 0;
			li {
				width: 49px;
				height: 50px;
				display: inline-block;
				color: rgba(255, 255, 255, 0.5);
				text-align: center;
				line-height: 50px;
				font-size: 25px;
				cursor: pointer;
			}
			li:first-child {
				border-bottom: 1px solid rgba(255, 255, 255, 0.15);
				color: #fff;
				background: rgba(0, 0, 0);
			}
			li:hover {
				color: #fff;
				background: rgba(0, 0, 0);
			}
		}
	}
}
</style>
