<template>
	<div class="left">
		<div class="header">
			二次供水能效管理平台
		</div>
		<div class="top">
			<span class="title">行业动态</span>
			<span><i class="el-icon-more" style="cursor:pointer;"></i></span>
		</div>
		<video
			id="my-video"
			class="video-js vjs-default-skin"
			controls
			preload="auto"
			width="207px"
		>
			<source
				src="http://ivi.bupt.edu.cn/hls/cctv1hd.m3u8"
				type="application/x-mpegURL"
			/>
		</video>
		<Calendar
			v-on:choseDay="clickDay"
			v-on:changeMonth="changeDate"
			v-on:isToday="clickToday"
		></Calendar>
		<div class="top">
			<span class="title">访客</span>
		</div>
		<div class="alert-success alert">
			<span><i class="el-icon-view"></i> 今日访问:1893次</span>
		</div>
		<div class="alert-info alert">
			<span><i class="el-icon-bell"></i> 累计访问:14562次</span>
		</div>
		<div class="weather">
			<div class="weather-top">
				<i class="el-icon-cloudy-and-sunny"></i>
				<div class="text-center">
					<div class="degree">-4</div>
					<div>多云,北风3-4级</div>
					<div>实时气温-4℃，较冷</div>
					<div class="d-value"><span>-9</span> / <span>14</span></div>
				</div>
			</div>
			<div class="weather-position">
				<input type="text" placeholder="位置..." class="form-control" />
			</div>
			<div class="weather-footer">
				<ul>
					<li>
						<span>一</span>
						<span><i class="el-icon-lightning"></i></span>
						<span>25</span>
					</li>
					<li>
						<span>二</span>
						<span><i class="el-icon-heavy-rain"></i></span>
						<span>27</span>
					</li>
					<li>
						<span>三</span>
						<span><i class="el-icon-sunny"></i></span>
						<span>28</span>
					</li>
					<li>
						<span>四</span>
						<span><i class="el-icon-cloudy-and-sunny"></i></span>
						<span>24</span>
					</li>
					<li>
						<span>五</span>
						<span><i class="el-icon-cloudy"></i></span>
						<span>26</span>
					</li>
					<li>
						<span>六</span>
						<span><i class="el-icon-sunrise"></i></span>
						<span>22</span>
					</li>
					<li>
						<span>日</span>
						<span><i class="el-icon-heavy-rain"></i></span>
						<span>28</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="top">
			<span class="title">系统导航</span>
		</div>
		<div class="list-group">
			<div class="list-group-item">
				<span>办公自动化OA系统</span>
				<span class="box box1"></span>
			</div>
			<div class="list-group-item">
				<span>财务管理系统</span>
				<span class="box box2"></span>
			</div>
			<div class="list-group-item">
				<span>机房管理系统</span>
				<span class="box box3"></span>
			</div>
			<div class="list-group-item">
				<span>空调管理系统</span>
				<span class="box box4"></span>
			</div>
		</div>
	</div>
</template>

<script>
import Calendar from 'vue-calendar-component'
export default {
	components: {
		Calendar,
	},
	methods: {
		//日历
		clickDay(data) {
			console.log(data) //选中某天
		},
		changeDate(data) {
			console.log(data) //左右点击切换月份
		},
		clickToday(data) {
			console.log(data) // 跳到了本月
		},
	},
}
</script>

<style scoped lang="scss">
.left {
	flex: 1;
	background: rgba(0, 0, 0, 0.35);
	// padding-left: 50px;
	.header {
		height: 50px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.15);
		color: #fff;
		text-align: center;
		line-height: 50px;
		cursor: pointer;
	}
	.header:hover {
		color: #ffd600;
	}
	.top {
		display: flex;
		justify-content: space-between;
		color: #fff;
		padding: 7px 10px 7px;
		align-items: center;
		background: rgba(0, 0, 0, 0.45);
		.title {
			font-size: 13px;
		}
	}
	video {
		margin-bottom: 10px;
	}
	::v-deep {
		.wh_content_all {
			background: rgba(0, 0, 0, 0.35);
			height: 200px;
			margin-bottom: 20px;
		}
		.wh_content_item .wh_isToday {
			background: rgba(0, 0, 0, 0.65);
			border-radius: 4px;
		}
		.wh_content_item .wh_chose_day {
			background: rgba(0, 0, 0, 0.65);
			border-radius: 4px;
		}
		.wh_content_item {
			font-size: 14px;
			height: 20px;
		}
		.wh_item_date,
		.wh_top_tag {
			width: 20px;
			height: 20px;
		}
		.wh_top_changge li {
			font-size: 16px;
		}
		.wh_item_date:hover {
			background: rgba(0, 0, 0, 0.65);
		}
		.wh_content {
			justify-content: space-around;
		}
	}
	.alert {
		border: 0;
		background: rgba(255, 255, 255, 0.7);
		font-family: open-sans-regular;
		font-size: 14px;
		padding: 15px;
		margin-bottom: 20px;
		border: 1px solid transparent;
		border-radius: 4px;
	}
	.alert-success {
		color: #069727;
		border-left: 5px solid #048f23;
	}
	.alert-info {
		color: #0b628d;
		border-left: 5px solid #0b628d;
	}
	.weather {
		.weather-top {
			height: 220px;
			background: #3bbec0;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			padding: 20px;
			display: flex;
			color: #fff;
			i {
				font-size: 60px;
			}
			.text-center {
				.degree {
					font-size: 30px;
				}
				.degree::after {
					content: 'o';
					font-size: 16px;
					position: relative;
					top: -12px;
				}
			}
		}
		.weather-position {
			background: #32a1a3;
			padding: 20px;
			.form-control {
				// background: rgba(0, 0, 0, 0.05);
				text-shadow: none;
				color: #fff;
				box-shadow: none;
				font-family: open-sans-regular;
				display: block;
				width: 100%;
				height: 34px;
				padding: 6px 12px;
				font-size: 14px;
				border: none;
				border-radius: 4px;
			}
		}
		.weather-footer {
			padding: 20px 0;
			ul {
				display: flex;
				padding: 0;
				justify-content: space-around;
				li {
					display: flex;
					flex-direction: column;
					color: #909090;
					border-right: 1px solid #ddd;
					cursor: pointer;
					text-shadow: 0 0 3px rgb(255 255 255 / 40%);
					span {
						margin: 5px 0;
						i {
							font-size: 20px;
						}
					}
					span:last-child::after {
						content: 'o';
						font-size: 12px;
						position: relative;
						top: -4px;
					}
				}
				li:last-child {
					border-right: none;
				}
				li:hover {
					color: #32a1a3;
				}
			}
		}
	}
	.list-group {
		padding: 10px 0;
		background: rgba(0, 0, 0, 0.45);
		.list-group-item {
			display: flex;
			justify-content: space-between;
			color: #fff;
			padding: 5px 10px;
			font-size: 14px;
			cursor: pointer;
			.box {
				display: inline-block;
				width: 10px;
				height: 10px;
				margin-top: 5px;
			}
			.box1 {
				background-color: #f0ad4e;
			}
			.box2 {
				background-color: #d9534f;
			}
			.box3 {
				background-color: #5bc0de;
			}
			.box4 {
				background-color: #5cb85c;
			}
		}
		.list-group-item:hover {
			color: #ffd600;
		}
	}
}
</style>
